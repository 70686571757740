/* src/components/FabButton.css */
.fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .fab button {
    background-color: #7066e0;
    border: none;
    color: white;
    padding: 15px;
    border-radius: 50%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fab button i {
    margin-right: 8px;
  }
  
  .fab button:hover {
    background-color: #5b54cc;
  }
  