/* Side menu styles */
.side-menu {
    position: fixed;
    top: 0;
    left: -250px; /* Start hidden to the left */
    width: 250px;
    height: 100%;
    background-color: white;
    /* color: #0A3D91; */
    overflow-x: hidden;
    transition: 0.3s; /* Smooth transition for sliding */
    z-index: 1000; /* Ensure the side menu is above other elements */
}

.side-menu.open {
    left: 0; /* Slide in the side menu */
}

.side-menu-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.side-menu-item {
    padding: 10px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    /* border-bottom: 1px solid #444; */
    /* color: #0A3D91; */
    text-align: left;
}

.side-menu-item:hover {
    background-color: #bebebe;
}

.close-btn {
    font-size: 30px;
    position: absolute;
    top: 10px;
    right: 20px;
    color: #0A3D91;
    cursor: pointer;
    border: none;
    background: none;
}

.close-btn:hover {
    color: #0A3D91;
}

/* Media query to hide the side menu on larger screens */
@media (min-width: 992px) {
    .side-menu {
        display: none; /* Hide side menu on larger screens */
    }
}

/* Styling for the menu link on larger screens */
.side-menu-link {
    cursor: pointer;
}

.side-menu-link:hover {
    background-color: #F9F9F9;
    border-radius: 4px;
}

.show { /* Class for showing the dropdown menu */
    visibility: visible !important;
    /* opacity: 1 !important; */
}


/* Fixed "Contact Us" button at the bottom-left corner */
.contact-floating-button {
    position: fixed;
    left: 20px; /* Distance from the left */
    bottom: 20px; /* Distance from the bottom */
    padding: 10px 20px;
    /* background-color: #20B2AA; Light Sea Green */
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure it's on top of other elements */
    font-size: 16px;
}

/* .contact-floating-button:hover { */
    /* background-color: #1e90ff; Light blue on hover */
/* } */

/* Modal styling */
/* .contact-modal {
    width: 400px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    outline: none;
}

.contact-modal-overlay {
    background-color: rgba(0, 0, 0, 0.5); 
} 
*/
